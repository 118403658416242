.wrap {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.wrap > div:not(:last-child) {
  margin-bottom: var(--pt5);
}

.article:first-child {
  flex: 5;
}

.article:last-child {
  flex: 4;
  align-self: flex-end;
}

/* Custom skeleton color background */

.skeleton div div:last-child {
  background-color: var(--ziggurat);
}

.skeleton div div:last-child::before {
  background: linear-gradient(
    to right,
    transparent 0%,
    var(--jagged-ice) 50%,
    transparent 100%
  );
}

@media (min-width: 768px) {
  .wrap {
    flex-direction: row;
  }

  .wrap > div:first-child {
    margin-bottom: 0;
  }
}
