.underlineContainer__colors {
  --underline-font-hover-color: var(--mine-shaft);
  --underline-bottom-hover-color: var(--mine-shaft);
}

.title {
  white-space: normal;
}

.imageskeleton {
  padding-bottom: var(--pt05);
  position: relative;
  width: 100%;
  height: 250px;
}

.content {
  display: grid;
  --grid_template_areas: "gridText" "gridImage";
  gap: var(--pt2);
  align-items: center;

  grid-template-areas: var(--grid_template_areas);
}

.grid__text {
  grid-area: gridText;
  display: flex;
  flex-direction: column;
  gap: var(--pt2);
}

.grid__image {
  grid-area: gridImage;
}

.button_width {
  width: fit-content;
}

@media (min-width: 768px) {
  .content {
    --grid_template_areas: "gridText gridImage";
    grid-auto-columns: 5fr 7fr;
  }
}

.imagewrapper {
  position: relative;
  padding-bottom: 62%;
  margin-bottom: var(--pt2);
}
