.container {
  display: flex;
  user-select: none;
  cursor: pointer;
}
.container:hover,
.container:focus {
  outline: 2px solid var(--blue);
}
.container:hover .fakeinput,
.container:focus .fakeinput {
  border: 1px solid white;
}
.fakeinput {
  position: relative;
  background: white;
  height: 56px;
  border: 1px solid var(--gray);
  flex-grow: 2;
  overflow: hidden;
}
.fakeinput p {
  color: var(--gray);
  line-height: 50px;
  text-align: left;
  margin-left: var(--pt2);
}

.hasQuery .fakeinput p {
  color: var(--mine-shaft);
}

.fakebutton {
  background: var(--blue);
  width: 68px;
  height: 56px;
}
.fakebutton p {
  color: white;
  font-family: var(--body-font-medium);
  font-size: 20px;
  line-height: 54px;
  text-align: center;
}
.placeholder {
  display: none;
}

.clear {
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(0);
  transition: transform 0.3s;
  cursor: pointer;
  background-color: white;

  /* override dynamic style */
  min-width: 56px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clear.visible {
  transform: scale(1);
  transition-timing-function: cubic-bezier(0.38, 1.95, 0.4, 0.4);
}

.clear path {
  fill: var(--blue);
}

@media (min-width: 464px) {
  .placeholder {
    display: initial;
  }
  .placeholderxs {
    display: none;
  }
}
